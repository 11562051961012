/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title, schemaMarkUp, ogImage, noIndex }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const defaultOGImage = ogImage || 'https://www.alopexid.com/images/Alopex-Full-Service-Digital-Agency.png'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || "Alaska Marketing Agency"}
      titleTemplate={(title + ` | Alopex Interaction Design`).length > 70 ? (title + ` | AlopexID`) : (title + ` | Alopex Interaction Design`)}
    > 
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="title" content={(`${title} | Alopex Interaction Design`).length > 70 ? (`${title} +  | AlopexID`) : (`${title} | ${defaultTitle}`) } />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title + ` | Alopex Interaction Design`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content='website' />
      <meta name="twitter:card" content='summary' />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ''} />
      <meta name="twitter:title" content={title + ` | Alopex Interaction Design`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:image" content={defaultOGImage} />
      {schemaMarkUp &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkUp)}</script>
      }

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
