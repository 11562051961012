import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from '@chakra-ui/react'
import { Helmet } from "react-helmet"
import Header from './Header'
import './layout.scss'

// export const ColorContext = createContext()

const Layout = ({ children, bgColor = '#f1f1f1' }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
  `)

  return (
    <>
      <Helmet
        meta={[
          {
            name: `facebook-domain-verification`,
            content: `ql666ddap538q38o7l420ju4yzzhdm`,
          }
        ]}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Alopex`} />
      <Box position="relative" backgroundColor={bgColor}>
        <Box as="main" position="relative" zIndex="1">
          {children}
        </Box>
        {/* <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
        <div className="elfsight-app-0ddfb105-0766-46b8-8e64-94d0ff839949"></div> */}
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
