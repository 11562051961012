import React, {useContext, useState} from 'react'
import ColorContext from '../context/ColorContext'
import {uniqueId} from 'lodash'

const Logo = () => {
  const { color } = useContext(ColorContext)
  const [ gid ] = useState(uniqueId('gradPrefix-'))

  return (
    <svg
      id="AlopexLogoMain"
      className="svgLogo alopexLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 80"
      style={{
        width: '100%',
        height: 'auto',
      }}
    >
      <defs>
        <linearGradient id={gid} gradientTransform="rotate(0)">
          <stop offset="5%" stopColor={color.alpha}></stop>
          <stop offset="95%" stopColor={color.bravo}></stop>
        </linearGradient>
      </defs>
      <title>Alopex Logo</title>
      <path fill={`url(#${gid})`} d="M7.67,38.4,0,.5,29.91,29.3,60,0,52.52,38.4,29.91,80Zm23,37.2L50.73,38.5,30.66,31.4Zm-1.42,0V31.4L9.37,38.5ZM51.29,37.1,57.73,4.3,31.32,30ZM8.9,37.1,28.58,30,2.37,4.8Z" />
    </svg>
  )
}

export default Logo